import React from 'react';
import { ChevronLeft } from 'react-feather';

import HeaderComponent from '@layout/HeaderComponent/index';
import { useAltaIntl } from '@shared/hook/useTranslate';

export interface HeadProfileProps {
  handleBack: () => void;
  title?: string;
}

const HeadProfile: React.FC<HeadProfileProps> = props => {
  const { formatMessage } = useAltaIntl();

  return (
    <div className="profile-page_head">
      <div className="profile-page_head_left">
        <div className="profile-page_head_left_back" onClick={props.handleBack}>
          <ChevronLeft />
        </div>
      </div>
      <div className="profile-page_head_center">
        <h1 className="profile-page_head_center_text">
          {props?.title ? formatMessage(props?.title) : formatMessage('profile.head.center.text')}
        </h1>
      </div>
      <div className="profile-page_head_right">
        <HeaderComponent />
      </div>
    </div>
  );
};

export default React.memo(HeadProfile);
