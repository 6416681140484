import { PresetColorType } from 'antd/lib/_util/colors';

import { languageEN, languageVN } from '@assets/images';
import ISelect from '@core/select';

export const LANGUAGE: ISelect<string>[] = [
  { value: 'vi', label: 'VIE', icon: languageVN },
  { value: 'en', label: 'ENG', icon: languageEN },
];

export const allSelect: ISelect = { label: 'common.all', value: undefined };

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL || '',
  APP_NAME: process.env.APP_NAME || 'CMS-SSO',
  LOGIN_PAGE: process.env.LOGIN_PAGE || '/',
  CLIENT_ID: process.env.CLIENT_ID || 'a60c3c46-d27b-4b8b-b207-dad739dcb9b6',

  ROUTER_HISTORY: process.env.ROUTER_HISTORY || 'routerHistorySSOMTC',
  MOBILE_WIDTH: Number(process.env.MOBILE_WIDTH) || 600,
  TABLET_WIDTH: Number(process.env.TABLET_WIDTH) || 1024,
  MESSAGE_SESSION_ID_ERROR: process.env.MESSAGE_SESSION_ID_ERROR || 'Mes.LoginSession.Expired',
  MESSAGE_USER_BLOCKED: process.env.MESSAGE_USER_BLOCKED || 'Mes.User.Blocked',
  TOKEN_IP_INFO: process.env.TOKEN_IP_INFO || 'c7f54ec959253b',
  MTC_SOLUTION: process.env.MTC_SOLUTION || 'https://mtcsolution.dev.altasoftware.vn/',
  MTC_SOLUTION_CONTACT: process.env.MTC_SOLUTION_CONTACT || 'contact/',
};

export const colors: PresetColorType[] = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
];

export default CONFIG;
